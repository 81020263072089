  import React, { useState } from 'react';
  import logo from '../../assets/nav/logo.svg';
  import Styles from '../../components/Navbar/Navbar.module.css';

  function Navbar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    // Función para alternar la visibilidad del menú en dispositivos móviles
    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
      <nav className="bg-dark">
        <div className="container mx-4 flex justify-start items-center py-4 relative md:justify-end md:container md:mx-auto">
        {/* Botón de hamburguesa */}
        <button
            className="md:hidden text-dark hover:text-dark-300"
            onClick={toggleMobileMenu}
          >
            <span className="text-xl">&#9776;</span>
          </button>
          <a href="/" className="absolute bottom-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img src={logo} alt="Logo" className="h-[18px]" />
          </a>
          <div className="hidden md:block">
            <ul className="flex space-x-8 mx-4 md:mx-0">
              <li>
                <a href="#howItWork" className={`text-[14px] hover:text-gray-300 ${Styles.label}`}>
                  HOW IT WORKS
                </a>
              </li>
              <li>
                <a href="#aboutUs" className={`text-[14px] hover:text-gray-300 ${Styles.label}`}>
                  ABOUT US
                </a>
              </li>
              <li>
                <a href="#FAQs" className={`text-[14px] hover:text-gray-300 ${Styles.label}`}>
                  FAQS
                </a>
              </li>
            </ul>
          </div>
          
        </div>
        {/* Menú en dispositivos móviles */}
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <ul className="mx-4">
              <li className='bg-white-300 border-t border-[#D9D9D9]'>
                <a href="#howItWork" className={`text-[14px] hover:text-gray-300 ${Styles.label}`}>
                  HOW IT WORKS
                </a>
              </li>
              <li className='bg-white-300 border-t border-[#D9D9D9]'>
                <a href="#aboutUs" className={`text-[14px] hover:text-gray-300 ${Styles.label}`}>
                  ABOUT US
                </a>
              </li>
              <li className='bg-white-300 border-t border-[#D9D9D9]'>
                <a href="#FAQs" className={`text-[14px] hover:text-gray-300 ${Styles.label}`}>
                  FAQS
                </a>
              </li>
            </ul>
          </div>
        )}
      </nav>
    );
  }

  export default Navbar;
