import React from "react";
import logo from "../../assets/footer/logo.png";
import manoGolf from "../../assets/footer/manoGolf.png";
import instaIcon from "../../assets/footer/instagram.svg";
import faceIcon from "../../assets/footer/Facebook.svg";
// import xIcon from "../../assets/footer/X.svg";
import Styles from "../../components/Footer/Footer.module.css";
import manoGolfDevice from '../../assets/footer/manoGolfDevice.svg';
import { APP_INSTAGRAM, APP_FACEBOOK } from '../../utils/constants';

function Footer() {
  return (
    <div className="relative">
      <div className="border-b border-gray-500 h-16 absolute md:top-[55%] top-[49%] z-50 md:w-[60.5em] w-[90%] md:ml-[14em] ml-[1em] 2xl:ml-[29.3em]"></div>
      <div className="bg-black grid grid-rows-[1fr auto auto] md:h-[35em] h-[39em] items-center justify-center relative">
        <img
          src={manoGolf}
          alt="ManoGolf"
          className="absolute  bottom-[21em] md:bottom-0 left-0 w-[35em] z-10 hidden md:block"
        />
         <img
          src={manoGolfDevice}
          alt="ManoGolf"
          className="absolute  md:bottom-0 left-0 w-auto z-10 md:hidden"
        />
        <img
          src={logo}
          alt="Logo"
          className="p-12 w-[42em] z-50 absolute top-[14em] md:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />

        <div className="flex  flex-col md:flex-row justify-between w-auto md:w-[60.6875em] mx-auto mt-[20em] md:mt-[17em]">
          <div className="flex  items-center justify-between space-x-9 px-3 md:px-0 mb-6 md:mb-0">
            <a
              href="/terms"
              className={`text-white text-[14px] hover:text-gray-400 ${Styles.label}`}
            >
              Terms
            </a>
            <a
              href="/privacy"
              className={`text-white text-[14px] hover:text-gray-400 ${Styles.label}`}
            >
              Privacy
            </a>
            {/* <a
              href="#Cookies"
              className={`text-white text-[14px] hover:text-gray-400 ${Styles.label}`}
            >
              Cookies
            </a> */}
          </div>

          <div className="flex  flex-wrap items-center space-x-9 mb-6 md:mb-0">
            <a
              href="#FAQs"
              className={`text-white text-[14px] hover:text-gray-400 ${Styles.label}`}
            >
              FAQs
            </a>
            <a
              href="#aboutUs"
              className={`text-white text-[14px] hover:text-gray-400 ${Styles.label}`}
            >
              About Us
            </a>
            <a
              href="#ContactUs"
              className={`text-white text-[14px] hover:text-gray-400 ${Styles.label}`}
            >
              Contact Us
            </a>
          </div>

          <div className="flex items-center w-[15em] md:w-auto justify-center z-20">
            <a 
            href={APP_INSTAGRAM}
            className="bg-black border-2 border-gray-500 rounded-full flex h-10 items-center w-10 m-2 hover:text-gray-400 hover:bg-gray-800">
              <img
                src={instaIcon}
                alt="InstaIcon"
                className="h-6 m-auto md:w-5 w-4 hover:text-gray-400"
              />
            </a>
            <a 
            href={APP_FACEBOOK}
            className="bg-black border-2 border-gray-500 rounded-full flex h-10 items-center w-10 m-2 overflow-hidden hover:text-gray-400 hover:bg-gray-800">
              <img src={faceIcon} alt="FaceIcon" className="h-6 m-auto md:w-5 w-4" />
            </a>
            {/* <div className="bg-black border-2 border-gray-500 rounded-full flex h-10 items-center w-10 m-2 overflow-hidden hover:text-gray-400 hover:bg-gray-800">
              <img src={xIcon} alt="XIcon" className="h-6 m-auto md:w-4 w-3" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
