import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../../assets/nav/logoNav.svg'
import legalService from '../../services/legals/LegalService';


const ContactUs = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await legalService.sendContactForm({ full_name: fullName, contact_email: email, message });
      navigate('/'); // Cambia "/home" a la ruta correcta de la página de inicio
    } catch (error) {
      console.error('Error sending contact form:', error);
    } finally {
      setLoading(false);
      setSent(true);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between w-auto mt-4 ml-8">
          <div className="flex items-center">
            <div className="mt-1 cursor-pointer" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} className="text-gray-600 text-lg" />
            </div>
            <h1 className="self-stretch text-neutral-700 text-2xl font-medium font-['Rubik'] leading-normal ml-6">
              Contact Us
            </h1>
          </div>
        </div>

        <div className="flex items-center justify-center mt-1.5">
          <div className="flex justify-center items-center w-full">
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="flex justify-center">
                <img src={logoImage} alt="" className="my-10" />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="full_name"
                  placeholder="Full Name"
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <textarea
                  name="message"
                  placeholder="Message"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  rows={5}
                />
              </div>
              <button
                type="submit"
                disabled={loading || sent}
                className={`w-full bg-[#89C96A] text-white py-2 px-4 rounded-md focus:outline-none relative ${
                  loading || sent ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              >
                {loading ? (
                  <>
                    <span>Sending </span>
                    <span className="loading loading-spinner loading-xs bg-white ml-0"></span>
                  </>
                ) : (
                  <span>{sent ? 'Sent' : 'Send'}</span>
                )}
              </button>
              <div className="flex justify-center my-10">
                <p>Contact: support@example.com</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
