import React from "react";
import { ToastContainer } from 'react-toastify';

//My Styles


//My components
import HeroSection from "../../components/HeroSection/HeroSection";
import HowItWork from "../../components/HowItWork/HowItWork";
import Benefit from "../../components/Benefit/Benefit";
import GridComponent from "../../components/GridComponent/GridComponent";
import AboutUs from "../../components/AboutUs/AboutUs";
import RotatedImage from "../../components/RotateImage/RotateImage";
import Questions from "../../components/Questions/Questions";




const Landing = () => {
  return (
    <>
      <HeroSection />
      <HowItWork />
      <Benefit></Benefit>
      <GridComponent></GridComponent>
      <AboutUs></AboutUs>
      <RotatedImage></RotatedImage>
      <Questions></Questions>
      <ToastContainer/>
      
    </>
  );
};

export default Landing;
