import React from "react";
import HeroImagen from "../../../assets/landing/hero.svg";

function HeroImage() {
  return (
    <div className="w-full">
      <img src={HeroImagen} className="w-full" alt="heroImage" loading="lazy"></img>
    </div>
  );
}

export default HeroImage;
