import React, { useState } from "react";
import { toast } from "react-toastify";

// import arrowImage from "../../../assets/landing/arrowHeroSection.svg";
import Styles from "../HeroForm/HeroForm.module.css";
import apiService from "../../../services/apiService";

function HeroForm() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Llamar a la función de registro de la API
      const response = await apiService.registerUser(formData);
  
      // Cambia el mensaje en el objeto response si es necesario
      response.message = "Thanks for subscribing!";
  
      console.log("Registro exitoso:", response);
      toast.success(response.message); // Toast de éxito
      // Aquí puedes manejar la respuesta exitosa, mostrar un mensaje, redirigir, etc.
    } catch (error) {
      console.error("Error en el registro:", error.message);
      toast.error(`Registration Error: ${error.message}`); // Toast de error
      // Aquí puedes manejar el error, mostrar un mensaje de error, etc.
    }
  };

  return (
    <div className="mx-4 md:ml-[4.5em] md:mr-[5.625em] mt-4 md:mt-[107px] relative">
      {/* <div>
        <span className={`${Styles.title1}`}>
          Rent and Share. Where Golfers meet Gear.
        </span>
      </div> */}
      <div className="my-[2.5em]">
        <span className={`${Styles.title2}`}>
          A New Way To Play
        </span>
      </div>
      <div>
        <span className={`${Styles.title3}`}>
          Rent Clubs or Earn with yours
        </span>
      </div>

      <form className="mt-12" onSubmit={handleRegister}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              name="first_name"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full rounded px-3 py-2 border border-gray-300"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              type="text"
              name="last_name"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full rounded px-3 py-2 border border-gray-300"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="mt-3">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full rounded px-3 py-2 border border-gray-300"
            placeholder="Email"
          />
        </div>
        <button
          type="submit"
          className={`${Styles.buttonForm} hover:bg-green-700
           text-white font-bold py-2 px-4 mt-3 w-full static`}
        >
          Join now!
        </button>
      </form>
      <div className="mt-4 pl-7 w-[16em] md:w-[21em]">
        <span className={Styles.descriptionButton}>
          {/* Be among the first to tee off with us and enjoy 3 months of{" "} */}
          Join our Beta Launch and enjoy 3 months of NO-FEE Lending and Renting
          {/* <span className={Styles.boldDescriptionButton}>commission-free</span>{" "}
          service! */}
        </span>
      </div>
      {/* <div className="absolute left-[75%] top-[85.5%] md:left-[62%] md:top-[81%]">
        <img src={arrowImage} alt="Arrow" />
      </div> */}
    </div>
  );
}

export default HeroForm;
