import React from 'react';
import styles from './RotateImage.module.css';
import imageRotate from '../../assets/landing/bagChatterRotada.png'; // Asegúrate de ajustar esta ruta a la ubicación correcta de tu imagen

function RotatedImage() {
    return (
        <div className={`h-[150px] md:h-[260px] mt-[40px] md:mt-0 ${styles.imageWrapper}`}>
            <img src={imageRotate} alt="Description" />
        </div>
    );
}

export default RotatedImage;
