import React, { useEffect, useState } from "react";
import styles from "./HowItWork.module.css";
import HowItWork1 from "../../assets/landing/HowItWork1.svg";
import HowItWork2 from "../../assets/landing/iPhone.png";

const pasos1 = [
  {
    titulo: "1. DISCOVER & DECIDE",
    descripcion:
      "Explore an array of listings and find the perfect gear that suits your style.",
  },
  {
    titulo: "2. CONNECT & CONVERSE",
    descripcion:
      "Reach out to the owner. Discuss specifics, clarify doubts, arrange a convenient handover, and ensure a perfect match for your game.",
  },
  {
    titulo: "3. PLAY YOUR BEST GAME",
    descripcion:
      "With the right gear in hand, you're all set to conquer the course. Make every swing count.",
  },
  {
    titulo: "4. RETURN & REVIEW",
    descripcion:
      "Once done, simply return the gear as agreed. Share your experience and help our community grow. It's that simple!",
  },
];

const pasos2 = [
  {
    titulo: "1. PIC + LIST",
    descripcion:
      "Capture your golf gear in its best light, craft an enticing description, and set your price.",
  },
  {
    titulo: "2. CHAT & ARRANGE",
    descripcion:
      "Interact with potential renters, lock in dates, and settle on pick-up details.",
  },
  {
    titulo: "3. EARN & RELAX",
    descripcion:
      "With each successful rental, watch your earnings grow while resting easy about your gear's security.",
  },
  {
    titulo: "4. RECEIVE & REVIEW",
    descripcion:
      "Get your gear back as agreed and share your experience with our community.",
  },
];

function HowItWork() {
  const [rentAnimationActive, setRentAnimationActive] = useState(false);
  const [offerAnimationActive, setOfferAnimationActive] = useState(false);
  const [rentAnimationPlayed, setRentAnimationPlayed] = useState(false);
  const [offerAnimationPlayed, setOfferAnimationPlayed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("howItWorkSection");
      if (section && !rentAnimationPlayed) {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 400) {
          setRentAnimationActive(true);
          setRentAnimationPlayed(true);
        }
      }

      const section2 = document.getElementById("howToOfferSection");
      if (section2 && !offerAnimationPlayed) {
        const rect2 = section2.getBoundingClientRect();
        if (rect2.top <= 400) {
          setOfferAnimationActive(true);
          setOfferAnimationPlayed(true);
        }
      }
    }; 

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [rentAnimationPlayed, offerAnimationPlayed]);

  return (
    <div className="mx-[16px] md:ml-[72px] md:relative mt-[101px] md:mt-[160px]" id="howItWork">
      <div className={`${styles.primaryTitle}`}>
        <span id="howItWorkSection" className="text-[24px] md:text-[48px]">How it works</span>
      </div>
      <div className="mb-[60px] md:mx-8">
        <span className={`text-[20px] md:text-[36px] ${styles.secondaryTitle}`}>How to rent?</span>
        <p className={`text-[14px] md:text-[24px] leading-[20px] md:leading-[36px] ${styles.secondaryTitleDescription}`}>
        It's never been this easy or affordable to get your hands on a set of golf clubs for first timers to scratch golfers.
        </p>
      </div>

      <div className="">
        <div
          className={`md:absolute md:top-[17%] md:left-[-8%] 2xl:left-[-4%] ${styles.imageContainer1}`}
        >
          <img
            src={HowItWork1}
            alt="Descripción"
            className=" md:h-[513px]"
          />
        </div>

        <div className="mt-0 md:flex md:justify-end md:mb-[152px] mb-0 md:mr-[72px]">
          <div className="w-auto md:w-[45vw]">
            <div
              className={`grid grid-cols-1 md:grid-cols-2 gap-6 md:justify-end ${styles.mosaico1}`}
            >
              {pasos1.map((paso, index) => (
                <div
                  className={`flex h-[144px] md:h-[244px] leaning-[20px] md:leading-[24px] text-[14px] md:text-[16px] ${styles.cuadro} ${
                    rentAnimationActive
                      ? index < 2
                        ? styles.grupo1
                        : styles.grupo2
                      : ""
                  }`}
                  key={paso.titulo}
                >
                  <span className={`text-[16px] md:text-[20px] leading-[24px] md:leading-[36px] ${styles.titleStep}`}>{paso.titulo}</span>
                  <p className={`text-[14px] md:text-[16px] leading-[20px] md:leading-[24px] ${styles.descriptionStep}`}>{paso.descripcion}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div>
        <span className={`text-[20px] md:text-[36px] ${styles.secondaryTitle}`} id="howToOfferSection">
          How to offer your golf clubs?
        </span>
        <p className={`text-[14px] md:text-[24px] leading-[20px] md:leading-[36px] ${styles.secondaryTitleDescription}`}>
         Earn money knowing your clubs are fully insured
        </p>
      </div>

      
      <div className="mt-0 md:flex md:justify-start md:mb-[168px] mb-0 grid w-[100%]">
        <div className="w-auto md:w-[45vw] md:mt-[56px]">
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 ${styles.mosaico2} `}>
            {pasos2.map((paso, index) => (
              <div
                className={`h-[144px] md:h-[244px] leaning-[20px] md:leading-[24px] text-[14px] md:text-[16px] ${styles.cuadro} ${
                  offerAnimationActive
                    ? index < 2
                      ? styles.grupo1
                      : styles.grupo2
                    : ""
                }`}
                key={paso.titulo}
              >
                <span className={`text-[16px] md:text-[20px] leading-[24px] md:leading-[36px] ${styles.titleStep}`}>{paso.titulo}</span>
                <p className={`text-[14px] md:text-[16px] leading-[20px] md:leading-[24px] ${styles.descriptionStep}`}>{paso.descripcion}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={`md:absolute md:top-[60%] md:left-[32.9%] w-[57em] 2xl:top-[60%] 2xl:left-[53%] order-first w-auto  ${styles.imageContainer2}`}>
          <img
            src={HowItWork2}
            alt="Descripción"
            className=" md:w-[58em] w-auto"
          />
        </div>

      </div>
    </div>
  );
}

export default HowItWork;
