import React from "react";
import golfPeople from "../../assets/landing/personaGolf.png";
import golfBag from "../../assets/landing/bolsoGolf.png";
import golfPeoples from "../../assets/landing/personasGolf.png";
import golfClub from "../../assets/landing/paloGolf.png";
import Styles from "../../components/GridComponent/GridComponent.module.css";

const contentDesktop = [
  { type: "image", src: golfClub },
  {
    type: "text",
    title: "DISCOVER YOUR IDEAL GEAR",
    description:(
      <div>
        Sample top-tier clubs without the commitment of buying.
        <br />
        Enjoy a variety without the hefty price tag. Switch up your gear game-to-game, and discover your perfect match.
      </div>
    ),
  },
  {
    type: "text",
    title: "TRAVEL LIGHT & PLAY RIGHT",
    description: "Jet Set, Stress-Free. Rent locally and play with the best.",
  },
  { type: "image", src: golfPeople },
  { type: "image", src: golfBag },
  {
    type: "text",
    title: "MONETIZE YOUR EQUIPMENT",
    description: `Turn idle equipment into income. Share your gear with fellow golfers and earn while you're off the green.`,
  },
  {
    type: "text",
    title: "TRUSTED COMMUNITY",
    description:
      "Our verified golfer community ensures your gear is in safe hands.",
  },
  { type: "image", src: golfPeoples },
];

const contentMobile = [
  { type: "image", src: golfClub },
  {
    type: "text",
    title: "DISCOVER YOUR IDEAL GEAR",
    description: (
      <div>
        Sample top-tier clubs without the commitment of buying.
        <br />
        <br />
        
        Enjoy a variety without the hefty price tag. Switch up your gear game-to-game, and discover your perfect match.
      </div>
    ),

  },
  { type: "image", src: golfPeople },
  {
    type: "text",
    title: "TRAVEL LIGHT & PLAY RIGHT",
    description: "Jet Set, Stress-Free. Rent locally and play with the best.",
  },
 
  { type: "image", src: golfBag },
  {
    type: "text",
    title: "MONETIZE YOUR EQUIPMENT",
    description: `Turn idle equipment into income. Share your gear with fellow golfers and earn while you're off the green.`,
  },
  { type: "image", src: golfPeoples },
  {
    type: "text",
    title: "TRUSTED COMMUNITY",
    description:
      "Our verified golfer community ensures your gear is in safe hands.",
  }
  
];

// ... (importaciones y estilos)

function GridComponent() {
  return (
    <div>
      <div className="hidden md:grid md:grid-cols-2 gap-0 w-full">
        {contentDesktop.map((item, index) => (
          <div key={index} className="flex items-center justify-center">
            {item.type === "image" ? (
              <div className="w-full max-w-full">
                <img src={item.src} alt="" className="w-auto h-auto" />
              </div>
            ) : (
              <div className="w-auto p-24 bg-white rounded-lg mx-auto">
                <h3 className={`mb-4 text-[16px] md:text-[28px] ${Styles.title}`}>
                  {item.title}
                </h3>
                <div className={`text-[14px] md:text-[20px] leading-[20px] md:leading-[36px] text-center md:text-left ${Styles.description}`}>
                  {item.description}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="grid md:hidden grid-cols-1 gap-0 w-full mb-12 md:mt-0">
        {contentMobile.map((item, index) => (
          <div key={index} className="flex items-center justify-center">
            {item.type === 'image' ? (
              <div className="w-full max-w-full">
                <img src={item.src} alt="" className="w-auto h-auto" />
              </div>
            ) : (
              <div className="max-w-lg px-4 py-8 bg-white rounded-lg mx-auto h-[236px] flex flex-col justify-center items-center">
                <h3 className={`mb-4 text-[16px] ${Styles.title}`}>{item.title}</h3>
                <div className={`text-[14px] leading-[20px] text-center ${Styles.description}`}>
                  {item.description}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GridComponent;

