import React, { useState, useEffect } from 'react';
import LegalService from '../../services/legals/LegalService';
import Toolbar from '../../utils/toolbar';

const Privacy = () => {
    const [privacy, setPrivacy] = useState(null);

    // useEffect(() => {
    //     const fetchPrivacy = async () => {
    //         try {
    //             const response = await LegalService.getPrivacy();
    //             setPrivacy(response);
    //             console.log("se cargo");
    //             console.log("contenido de privacy", privacy);
    //         } catch (error) {
    //             console.error('Error fetching privacy:', error);
    //         }
    //     };

    //     fetchPrivacy();

    //     // Clean up function
    //     return () => {
    //         setPrivacy(null);
    //     };
    // }, [privacy]);
    useEffect(() => {
        let isMounted = true; // Variable para verificar si el componente está montado
    
        const fetchPrivacy = async () => {
            try {
                const response = await LegalService.getPrivacy();
                if (isMounted) {
                    setPrivacy(response);
                    console.log("se cargo");
                    console.log("contenido de privacy", response);
                }
            } catch (error) {
                console.error('Error fetching privacy:', error);
            }
        };
    
        fetchPrivacy();
        //Comentario nuevo
    //nuevo comentario
        // Clean up function
        return () => {
            isMounted = false; // Cuando el componente se desmonta, establece isMounted en false
        };
    }, []);
    

    return (
        <div className='min-h-[620px]'>
            <Toolbar title={'Privacy Policy'}/>
            <p className='mx-8'>{privacy?.description}</p>
        </div>
    );
};

export default Privacy;
