import React from "react";
import Privacy from "../../components/Privacy/privacy";

//My components

const PrivacyPage = () => {
  return (
    <>
     <Privacy></Privacy>
    </>
  );
};

export default PrivacyPage;
