
// import React from 'react'
// import ArrowLeft from '../assets/Sidebar/ArrowLeft.svg'
// import { useNavigate } from 'react-router-dom';

// type propsType = { title: string }

// const Toolbar:React.FC<propsType> = ({ title }) => {
    
//     const navigate = useNavigate();
//   return (
//     <div className="w-full mb-4 h-16 px-8 py-3 bg-white backdrop-blur-[35px] justify-start items-center gap-4 inline-flex">
//     <div 
//     onClick={() => navigate(-1)}
//     className="w-6 h-6 relative cursor-pointer">
//         <img src={ArrowLeft} alt='arrow'/>
//     </div>
//     <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
//         <div className="self-stretch text-neutral-700 text-xl font-medium font-['Rubik'] leading-normal">{title}</div>
//     </div>
// </div>
//   )
// }

// export default Toolbar

import React from 'react';
import ArrowLeft from '../assets/Sidebar/ArrowLeft.svg';
import { useNavigate } from 'react-router-dom';

const Toolbar = ({ title }) => {
    const navigate = useNavigate();

    return (
        React.createElement("div", { className: "w-full mb-4 h-16 px-8 py-3 bg-white backdrop-blur-[35px] justify-start items-center gap-4 inline-flex" },
            React.createElement("div", { onClick: () => navigate(-1), className: "w-6 h-6 relative cursor-pointer" },
                React.createElement("img", { src: ArrowLeft, alt: 'arrow' })),
            React.createElement("div", { className: "grow shrink basis-0 flex-col justify-center items-start inline-flex" },
                React.createElement("div", { className: "self-stretch text-neutral-700 text-xl font-medium font-['Rubik'] leading-normal" }, title))
        )
    );
};

export default Toolbar;
