import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './views/Landing/Landing';
import Layout from './routes/layout';
import TermsPage from './views/Terms/Terms';
import PrivacyPage from './views/Privacy/Privacy';
import ContactUs from './views/ContactUs/ContactUs';


  // Asegúrate de que la ruta de importación sea la correcta

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          {/* Agrega más rutas aquí según sea necesario */}
        </Route>
        <Route path="/terms" element={<TermsPage/>}>
        {/* <Route path="/privacy" element={<PrivacyPage/>}></Route> */}

        </Route>
        <Route path="/privacy" element={<PrivacyPage/>}>
        </Route>
        <Route path="/contactUs" element={<ContactUs/>}>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
