// apiService.js

function getBaseUrl() {
  const env = process.env.REACT_APP_API_ENV || 'development';

  switch (env) {
    case 'development':
      return process.env.REACT_APP_BASE_URL_DEV || 'https://bagchatter.infinixsoft.com/';
    case 'production':
      return process.env.REACT_APP_BASE_URL_PROD || 'https://backend.bagchatter.com/';
    default:
      throw new Error(`Entorno no reconocido: ${env}`);
  }
}

async function registerUser(userData) {
  try {
    const registerUrl = `${getBaseUrl()}auth/pre_register`;
    const response = await fetch(registerUrl, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      
      // Mensajes personalizados según el código de error
      let errorMessage;
      switch (errorData.code) {
        case 'EMAIL_ALREADY_IN_USE':
          errorMessage = 'The email address is already in use.';
          break;
        // Agrega más casos según sea necesario
        default:
          errorMessage = 'An unknown error occurred. Please try again later.';
          break;
      }

      throw new Error(errorMessage);
      //comentarionuevo
    }

    return response.json();
  } catch (error) {
    throw error;
  }
}

const apiService = {
  registerUser,
};
export default apiService;
