import React from "react";
import HeroImage from "./HeroImage/HeroImage";
import HeroForm from "./HeroForm/HeroForm";

function HeroSection() {
  return (
    <div>
    <div className="hidden md:flex">
      <div className="md:w-1/2 md:min-h-[160px] mb-[160px]">
        <HeroForm />
      </div>
      <div className="md:w-1/2 md:min-h-[160px] mb-[160px]">
        <HeroImage></HeroImage>
      </div>
    </div>
      <div className="block md:hidden columns-1">
        <HeroImage />
        <HeroForm />
      </div>
  </div>
  );
}

export default HeroSection;
