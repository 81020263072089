import React from "react";
import Terms from "../../components/Terms/terms";

//My components

const TermsPage = () => {
  return (
    <>
     <Terms></Terms>
    </>
  );
};

export default TermsPage;
