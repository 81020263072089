
// import legalService from '../../services/legals/legalService'
// import React from 'react'
// import { useQuery } from 'react-query'
// import Toolbar from '../../utils/toolbar'


// const Terms = () => {
//     const {data:terms} = useQuery({
//         queryKey: ['terms'],
//         queryFn: legalService.getTerms
//       })
//   return (
//     <div className='min-h-[620px]'>
//         <Toolbar title={'Terms and Conditions'}/>
//         <p className='mx-8'>{terms?.description}</p>
//     </div>
//   )
// }
// export default Terms
import React, { useState, useEffect } from 'react';
import LegalService from '../../services/legals/LegalService';
import Toolbar from '../../utils/toolbar';

const Terms = () => {
    const [terms, setTerms] = useState(null);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const response = await LegalService.getTerms();
                setTerms(response);
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        fetchTerms();

        // Clean up function
        return () => {
            setTerms(null);
        };
    }, []);

    return (
        <div className='min-h-[620px]'>
            <Toolbar title={'Terms and Conditions'}/>
            <p className='mx-8'>{terms?.description}</p>
        </div>
    );
};

export default Terms;

