import React from "react";
import styles from "./Benefit.module.css";
import saveMoney from "../../assets/landing/cerdo.png";
import makeMoney from "../../assets/landing/ahorro.png";
import community from "../../assets/landing/comunidad.png";

function Benefit() {
  const items = [
    {
      image: saveMoney,
      title: "Save money",
      description: "Swing Smart, Spend Less!",
    },
    {
      image: makeMoney,
      title: "Make money",
      description: "Turn Your Clubs into Cash!",
    },
    {
      image: community,
      title: "Build a community",
      description: "Connecting Golfers One Club at a Time!",
    },
  ];

  return (
    <div className="md:px-[144px] bg-[#61B889] px-4 py-8 md:py-12 mt-[48px] mb-[64px] md:mb[64px] ">
      <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 md:space-x-auto w-auto">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-white md:py-4"
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-12 h-12 md:w-10 md:h-10 object-cover mb-2"
            />
            <h3 className={`text-[24px] md:text-[36px] ${styles.title}`}>
              {item.title}
            </h3>
            <p className={`text-[14px] md:text-[18px] ${styles.description}`}>
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Benefit;
