// import TermModel from '../../models/term'

// const getTerms = async():Promise<TermModel> => {
//     try {
//         const response = await fetch(`${process.env.NEXT_PUBLIC_API_DEV}/terms`)

//         return await response.json()
//     } catch(error) {
//         throw(error)
//     }
// }

// const getPrivacy = async():Promise<TermModel> => {
//     try {
//         const response = await fetch(`${process.env.NEXT_PUBLIC_API_DEV}/privacy`)

//         return await response.json()
//     } catch(error) {
//         throw(error)
//     }
// }

// const legalService = {
//     getTerms, getPrivacy
// }

// export default legalService

// import TermModel from '../../models/term';

const getTerms = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/terms`);
        console.log("devuelve bien terms");
        return await response.json();
    } catch (error) {
        throw error;
    }
};

const getPrivacy = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/privacy`);
        return await response.json();
    } catch (error) {
        throw error;
    }
};

const sendContactForm = async (formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (!response.ok) {
            throw new Error('Failed to send contact form');
        }

        return await response.json();
    } catch (error) {
        console.error('Error sending contact form:', error);
        throw error;
    }
};

const getFaqs = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/faqs`);
        return await response.json();
    } catch (error) {
        throw error;
    }
    //nuevo comentario
};

const legalService = {
    getTerms,
    getPrivacy,
    sendContactForm,
    getFaqs
};

export default legalService;
