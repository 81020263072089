// import React from "react";
// import Styles from "../../components/AboutUs/AboutUs.module.css";
// import JasonImage from '../../assets/aboutUs/jason.svg'
// import VikramImage from '../../assets/aboutUs/vikram.svg';

// const teamMembers = [
//   {
//     image: JasonImage,
//     name: "JASON RODARTE-REDMOND",
//     title: "Co-founder",
//     // description: `Jason earned his J.D. from UC Davis Law School and has spent the last 10 years growing D2C, blockchain and marketplace startups to $500m in valuation. He combines his expertise in user acquisition, performance data, and optimizing for scale with his love of outdoor summer and winter sports available year-round in California.`,
//     description: `Jason has spent the last 10 years building customer-facing blockchain, e-commerce and marketplace startups to $500m in valuation. His expertise in user acquisition, consumer data, and technology combines with his love of outdoor sports available year-round in California.`,
//   },
//   {
//     image: VikramImage,
//     name: "VIKRAM PRABHU",
//     title: "Co-founder",
//     description: `With a foundation in finance, economics and startup investing, Vikram is dedicated to transforming the sports tech landscape. He's fueled by his passion for golf to ensure Fairway Tech is both financially savvy and deeply attuned to the needs of sports enthusiasts.`,
//   },
// ];

// function Card({ member }) {
//   return (
//     <div className="w-auto md:w-1/2 p-6 border border-gray-300 bg-[#fff] rounded shadow-md mb-8 h-385px md:h-[560px]">
//       <div className="w-32 h-32 mx-auto mb-8 overflow-hidden rounded-full bg-black">
//         <img
//           src={member.image}
//           alt={member.name}
//           className="w-full h-full object-contain"
//         />
//       </div>
//       <h2
//         className={`text-[16px] md:text-[20px] text-center mb-2 ${Styles.name}`}
//       >
//         {member.name}
//       </h2>
//       <h3
//         className={`text-[12px] md:text-[16px] text-center mb-6 ${Styles.title}`}
//       >
//         {member.title}
//       </h3>
//       <p
//         className={`text-[12px] md:text-[18px] text-center leading-[18px] md:leading-[32px] ${Styles.description}`}
//       >
//         {member.description}
//       </p>
//     </div>
//   );
// }

// function AboutUs() {
//   return (
//     <div
//       className={`bg-contain md:bg-cover bg-center w-full  pb-[97px] ${Styles.containerAboutUs}`}
//     >
//       <div className="container mx-auto" id="aboutUs">
//         <div className="text-center md:mb-20 mb-10">
//           <h1
//             className={`text-[24px] md:text-[48px] md:pt-[157px] pt-[112px] ${Styles.AboutUs}`}
//           >
//             About us
//           </h1>
//         </div>
//         <div className="flex justify-center md:space-x-14 space-y-14 md:space-y-0 flex-wrap md:flex-nowrap mx-4 md:mx-0">
//           {teamMembers.map((member) => (
//             <Card key={member.name} member={member} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default AboutUs;


import React, { useState, useEffect } from "react";
import Styles from "../../components/AboutUs/AboutUs.module.css";
// import JasonImage from '../../assets/aboutUs/jason.svg';
// import VikramImage from '../../assets/aboutUs/vikram.svg';

function AboutUs() {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    // Realizar la solicitud HTTP GET cuando el componente se monta
    fetch('https://bagchatter.infinixsoft.com/about_us')
      .then(response => response.json())
      .then(data => {
        // Una vez que se recibe la respuesta, actualiza el estado con los datos del equipo
        setTeamMembers(data);
      })
      .catch(error => console.error('Error fetching team members:', error));
  }, []);

  return (
    <div className={`bg-contain md:bg-cover bg-center w-full pb-[97px] ${Styles.containerAboutUs}`}>
      <div className="container mx-auto" id="aboutUs">
        <div className="text-center md:mb-20 mb-10">
          <h1 className={`text-[24px] md:text-[48px] md:pt-[157px] pt-[112px] ${Styles.AboutUs}`}>
            About us
          </h1>
        </div>
        <div className="flex justify-center md:space-x-14 space-y-14 md:space-y-0 flex-wrap md:flex-nowrap mx-4 md:mx-0">
          {teamMembers.map((member, index) => (
            <Card key={index} member={member} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Card({ member }) {
  // Utiliza la información del miembro para renderizar la tarjeta
  return (
    <div className="w-auto md:w-1/2 p-6 border border-gray-300 bg-[#fff] rounded shadow-md mb-8 h-385px md:h-[560px]">
      <div className="w-32 h-32 mx-auto mb-8 overflow-hidden rounded-full bg-black">
        <img src={member.picture} alt={member.name} className="w-full h-full object-contain" />
      </div>
      <h2 className={`text-[16px] md:text-[20px] text-center mb-2 ${Styles.name}`}>
        {member.name}
      </h2>
      <h3 className={`text-[12px] md:text-[16px] text-center mb-6 ${Styles.title}`}>
        {member.title}
      </h3>
      <p className={`text-[12px] md:text-[18px] text-center leading-[18px] md:leading-[32px] ${Styles.description}`}>
        {member.description}
      </p>
    </div>
  );
}

export default AboutUs;
